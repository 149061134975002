var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-drawer',{staticStyle:{"height":"calc(100% - 55px)","overflow":"auto","padding-bottom":"53px"},attrs:{"title":"新增城市","maskClosable":false,"width":"650","placement":"right","closable":false,"visible":_vm.cityAddVisiable},on:{"close":_vm.onClose}},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',_vm._b({attrs:{"label":"城市名称"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['cityName',
                 {rules: [
                  { required: true, message: '不能为空'},
                  { max: 20, message: '长度不能超过20个字符'}
                ]}]),expression:"['cityName',\n                 {rules: [\n                  { required: true, message: '不能为空'},\n                  { max: 20, message: '长度不能超过20个字符'}\n                ]}]"}],staticStyle:{"width":"100%"}})],1),_c('a-form-item',_vm._b({attrs:{"label":"简介"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['introduce',
                 {rules: [
                  { max: 200, message: '长度不能超过200个字符'}
                ]}]),expression:"['introduce',\n                 {rules: [\n                  { max: 200, message: '长度不能超过200个字符'}\n                ]}]"}]})],1),_c('a-form-item',_vm._b({attrs:{"label":"经度"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['longitude',
                 {rules: [
                  { max: 50, message: '长度不能超过50个字符'}
                ]}]),expression:"['longitude',\n                 {rules: [\n                  { max: 50, message: '长度不能超过50个字符'}\n                ]}]"}]})],1),_c('a-form-item',_vm._b({attrs:{"label":"纬度"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['latitude',
                 {rules: [
                  { max: 50, message: '长度不能超过50个字符'}
                ]}]),expression:"['latitude',\n                 {rules: [\n                  { max: 50, message: '长度不能超过50个字符'}\n                ]}]"}]})],1)],1),_c('div',{staticClass:"drawer-bootom-button"},[_c('a-popconfirm',{attrs:{"title":"确定放弃编辑？","okText":"确定","cancelText":"取消"},on:{"confirm":_vm.onClose}},[_c('a-button',{staticStyle:{"margin-right":".8rem"}},[_vm._v("取消")])],1),_c('a-button',{attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.handleSubmit}},[_vm._v("提交")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }